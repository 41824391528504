import React, { useState, useRef } from 'react';
import './Menu.css';
import logo from '../../assets/vedi_black.png'

const menuData = [
  {
    title: "PREDJELA / APPETIZERS",
    items: [
      { name: "Goveđa pašteta / Beef pate", price: "9,50€", description: "Goveća pašteta 150 g / maslac / marinirani luk / aceto krema / rikola", translation: "Beef pate 150 g / butter / marinated onion / aceto cream" },
      { name: "Pršut i sirevi / Prosciutto and cheese", price: "10,00€", description: "Dalmatinski pršut 75g / Domaći bilogorski sir 75g / rikola / pesto od rajčice", translation: "Dalmatian prosciutto 75g / cheese 75g / arugula / tomato pesto" },
      { name: "Goveđi carpaccio / Beef Carpaccio", price: "11,00€", description: "Govedina 100g / rikola / marinirani luk / pesto / aceto krema / parmezan", translation: "Beef 100g / arugula / marinated onion / pesto / aceto cream / parmesan cheese" },
      { name: "Iz mora za dvoje / From the sea for two", price: "19,50€", description: "Dimljena tuna 30g / sabljarka 30g / bakalar bianco 30g / inčuni 30g / gamberi 30g / dimljeni brancin 30g", translation: "Smoked tuna 30g / swordfish 30g / white cod 30g / anchovies 30g / prawns 30g / smoked sea bass 30g" }
    ]
  },
  {
    title: "JUHA / SOUP",
    items: [
      { name: "Goveđa juha / Beef soup", price: "3,20€", description: "Goveđa juha / gris knedla", translation: "Beef soup / dumpling" },
      { name: "Juha od vrganja / Mushroom soup", price: "4,00€", description: "Juha od vrganja", translation: "Mushroom soup" }
    ]
  },
  {
    title: "TJESTENINE / PASTA",
    items: [
      { name: "Pljukanci s biftekom / Pljukanci with steak", price: "13,00€", description: "Pljukanci / Biftek 100g / umak od zelenog papra / sušena rajčica / parmezan", translation: "Pljukanci / Steak 100g / green pepper sauce / dried tomato / parmesan cheese" },
      { name: "Pljukanci s tartufima / Pljukanci with truffles", price: "14,00€", description: "Pljukanci / svježi tartufi / parmezan", translation: "Pljukanci / fresh truffles / parmesan cheese" },
      { name: "Lingvini s gamberima / Linguine ai gamberi", price: "13,00€", description: "Lingvini / kozice 100g / krema od graška / cherry rajčica / tikvice", translation: "Linguine / prawns 100g / pea cream / cherry tomato / zucchini" },
      { name: "Lingvini s piletinom / Linguine with chicken", price: "11,00€", description: "Lingvini / piletina 100g / umak od rajčice / tikvice / paprika / soja sos / vrhnje za kuhanje / parmezan", translation: "Linguini / chicken 100g / tomato sauce / zucchini / pepper / soya sauce / cooking cream / parmesan cheese" }
    ]
  },
  {
    title: "RIŽOTO / RISOTTO",
    items: [
      { name: "Rižoto s piletinom i vrganjima / Chicken and Porcini mushrooms risotto", price: "11,88€", description: "riža / piletina / pršut / crno vino / parmezan / juha od vrganja", translation: "rice / chicken / prosciutto / red wine / parmesan cheese / porcini mushroom soup" },
      { name: "Vege rižoto / Veggie risotto", price: "10,00€", description: "riža / paprika / tikvice / cherry rajčica / krema od graška / parmezan", translation: "rice / pepper / zucchini / cherry tomato / pea cream / parmesan cheese" }
    ]
  },
  {
    title: "GLAVNA JELA / MAIN DISHES",
    items: [
      { name: "Ribeye steak", price: "24,88€", description: "Ribeye steak 250g / pečeni krumpir / povrće sa žara / umak od zelenog papra", translation: "Ribeye steak 250g / baked potatoes / grill vegetables / green pepper sauce" },
      { name: "Biftek / Beefsteak", price: "26,50€", description: "Biftek 250g / pire od krumpira / kuhano povrće / umak od gorušice", translation: "Beefsteak 250g / mashed potatoes / cooked vegetables / mustard sauce" },
      { name: "Goveđi obrazi u pršutu / Prosciutto wrapped Beef cheeks", price: "17,50€", description: "Goveđi obrazi 250g / pire od krumpira / kuhano povrće / umak od gorušice / mileram", translation: "Beef cheeks 250g / mashed potatoes / cooked vegetables / mustard sauce / sour cream" },
      { name: "Grill piletina / Grilled chicken", price: "12,88€", description: "Grill piletina 250g / pečeni krumpir / parmezan umak / rikola", translation: "Grilled chicken 250g / baked potatoes / parmesan sauce / arugula" },
      { name: "Svinjska rebarca / Pork ribs", price: "15,88€", description: "Svinjska rebarca 600g / dolar čips / pigi sos (slatko/kiselo/pikantni premaz od paprike) / umak od zelenog papra", translation: "Pork ribs 600g / dollar chips / pigi sauce (sweet/sour/spicy peppers coating)/ green pepper sauce" },
      { name: "Vedi grill", price: "13,00€", description: "kobasica 70g / govedina 70g / piletina 70g / svinjetina 70g / pečeni krumpir / đuveč riža / umak od selenog papra", translation: "sausage 70g / beef 70g / chicken 70g / pork 70g / baked potatoes / đuveč rice / green pepper sauce" },
      { name: "Smash burger / Smash burger", price: "10,00€", description: "govedina 150g / cheddar sir / domaći umak / karamelizirani luk / kiseli rastavci / dolar čips", translation: "beef 150g / cheddar cheese / home made sauce / caramelized onions / pickles / dollar chips" },
      { name: "Sporo kuhana Black Angus rebra / Slow cooked Black Angus ribs", price: "22,00€", description: "Black Angus goveđa rebra 500g / salata / pire od krumpira", translation: "Black Angus beef ribs 500g / salad / mashed potatoes" }
    ]
  },
  {
    title: "RIBA / FISH",
    items: [
      { name: "File Brancina / Sea bass fillet", price: "14,00€", description: "File Brancina 200g / pire od krimpira / kuhano povrće / krema od celera", translation: "Sea bass fillet 200g / mashed potatoes / cooked vegetables / celery cream" },
      { name: "Tuna Steak", price: "17,88€", description: "Odrezak tune 250g / kuhana blitva s krumpirom", translation: "Tuna steak 250g / cooked chard with potatoes" },
      { name: "Divovski Krak lignje a'la peka / Giant Squid Leg a'la peka", price: "16,50€", description: "Divovski krak lignje 250g / umak od rajčice / cherry rajčica / krumpir / luk / masline", translation: "Giant squid leg 250g / tomato sauce / cherry tomato / potato / onion / olives" }
    ]
  },
  {
    title: "SALATA / SALAD",
    items: [
      { name: "Sezonska salata / Seasonal salad", price: "4,00€", description: "", translation: "" },
      { name: "Cezar salata / Caesar salad", price: "9,20€", description: "zelena salata / piletina sa žara / cherry rajčica / hrskavi krutoni / cezar dressing / parmezan", translation: "green salad / grilled chicken / cherry tomato / crispy croutons / caesar dressing / parmesan cheese" }
    ]
  },
  {
    title: "PRILOZI / SIDE DISHES",
    items: [
      { name: "Dollar chips", price: "3,30€", description: "", translation: "" },
      { name: "Kuhano povrće / Cooked vegetables", price: "3,30€", description: "", translation: "" },
      { name: "Pečeni krumpir / Baked potatoes", price: "3,30€", description: "", translation: "" },
      { name: "Pire krumpir / Mashed potatoes", price: "3,10€", description: "", translation: "" },
      { name: "Riža na maslacu / Butter rice", price: "2,80€", description: "", translation: "" }
    ]
  },
  {
    title: "UMACI / SAUCE",
    items: [
      { name: "Umak od zelenog papra / Green pepper sauce", price: "4,00€", description: "", translation: "" },
      { name: "Umak od gorušice / Mustard sauce", price: "4,00€", description: "", translation: "" },
      { name: "Parmezan umak / Parmesan sauce", price: "4,00€", description: "", translation: "" },
      { name: "Ostali umaci / Other sauces", price: "2,00€", description: "", translation: "" }
    ]
  },
  {
    title: "DESERTI / DESSERTS",
    items: [
      { name: "Deserti dana 150g / Desserts of the day 150g", price: "5,00€", description: "", translation: "" }
    ]
  },
  {
    title: "PIĆA / DRINKS",
    subcategories: [
      {
        subtitle: "Rakije i likeri / Schnapps and liqueurs (Destilerija Unikat / Unikat Distillery)",
        items: [
          { name: "Rakija od dunje / Quince schnapps", price: "0,03l - 2,40€", description: "", translation: "" },
          { name: "Šljivovica / Plum schnapps", price: "0,03l - 2,40€", description: "", translation: "" },
          { name: "Viljamovka / Gold pear schnapps", price: "0,03l - 2,40€", description: "", translation: "" },
          { name: "Rakija Malina / Raspberry schnapps", price: "0,03l - 2,80€", description: "", translation: "" },
          { name: "Liker od višnje / Cherry liqueur", price: "0,03l - 1,80€", description: "", translation: "" },
          { name: "Pelinkovac Unikat", price: "0,03l - 1,80€", description: "", translation: "" },
          { name: "Limuncello", price: "0,03l - 1,80€", description: "", translation: "" },
          { name: "Teranino", price: "0,03l - 2,40€", description: "", translation: "" }
        ]
      },
      {
        subtitle: "Žestoka pića / Spirits",
        items: [
          { name: "Jack Daniels", price: "0,03l - 2,40€", description: "", translation: "" },
          { name: "Jagermeister", price: "0,03l - 2,40€", description: "", translation: "" },
          { name: "Amaro", price: "0,03l - 1,60€", description: "", translation: "" },
          { name: "Pelinkovac Badel", price: "0,03l - 1,60€", description: "", translation: "" },
          { name: "Pelinkovac Antique", price: "0,03l - 1,90€", description: "", translation: "" },
          { name: "Prima Brand Badel", price: "0,03l - 1,60€", description: "", translation: "" },
          { name: "Malibu", price: "0,03l - 1,80€", description: "", translation: "" },
          { name: "Vodka Three Sixty", price: "0,03l - 1,70€", description: "", translation: "" },
          { name: "Rum Havana", price: "0,03l - 1,90€", description: "", translation: "" },
          { name: "Jameson Black Barrel", price: "0,03l - 4,50€", description: "", translation: "" },
          { name: "Whiskey Ardbeg 10 YO", price: "0,03l - 7,50€", description: "", translation: "" },
          { name: "Rum Barcelo Imperijal", price: "0,03l - 3,50€", description: "", translation: "" },
          { name: "Gin Pennant Destilerija Old Hils / Old Hils Distillery", price: "0,03l - 4,00€", description: "", translation: "" }
        ]
      },
      {
        subtitle: "VINO / WINE",
        items: [
          { name: "G Točka Crno", price: "0,75l - 15,88€ / 0,1l - 2,20€", description: "", translation: "" },
          { name: "Graševina Galić", price: "0,75l - 23,88€ / 0,1l - 3,50€", description: "", translation: "" },
          { name: "Sauvignon Blanc", price: "0,75l - 27,50€ / 0,1l - 3,90€", description: "", translation: "" },
          { name: "G Točka Bijela", price: "0,75l - 13,88€ / 0,1l - 1,90€", description: "", translation: "" },
          { name: "Chardonnnay", price: "0,75l - 30,00€", description: "", translation: "" },
          { name: "Rose Galić", price: "0,75l - 26,88€ / 0,1l - 3,80€", description: "", translation: "" },
          { name: "Cabernet Sauvignon", price: "0,75l - 34,00€", description: "", translation: "" },
          { name: "Teran", price: "0,75l - 34,00€", description: "", translation: "" },
          { name: "Teran stolno vino / table wine", price: "1l - 13,50€ / 0,1l - 1,80€", description: "", translation: "" },
          { name: "Merlot", price: "0,75l - 34,00€", description: "", translation: "" },
          { name: "Merlot stolno vino / table wine", price: "1l - 13,50€ / 0,1l - 1,80€", description: "", translation: "" },
          { name: "Pjenušac Astoria Lounge", price: "0,75l - 15,20€", description: "", translation: "" },
          { name: "Somersby (jabuka / kruška / borovnica / jagoda / mandarina )", price: "0,33l - 2,80€", description: "", translation: "" },
          { name: "Malvazija", price: "0,75l - 28,00€ / 0,1l - 4,00€", description: "", translation: "" },
          { name: "Malvazija stolno vino / table wine", price: "1l - 12,00€ / 0,1l - 1,50€", description: "", translation: "" },
          { name: "Muškat", price: "0,75l - 20,00€ / 0,1l - 2,88€", description: "", translation: "" }
        ]
      },
      {
        subtitle: "PIVO / BEER",
        items: [
          { name: "Pan Lager", price: "0,5l - 2,50€", description: "", translation: "" },
          { name: "Pan Zlatni", price: "0,5l - 2,50€", description: "", translation: "" },
          { name: "Pan Radler", price: "0,5l - 2,50€", description: "", translation: "" },
          { name: "Pan Bezalkoholni / Non-alcoholic", price: "0,5l - 2,50€", description: "", translation: "" },
          { name: "Tuborg", price: "0,5l - 2,70€ / 0,33l - 2,20€", description: "", translation: "" },
          { name: "Budweiser tamno / dark", price: "0,5l - 2,60€", description: "", translation: "" },
          { name: "Budweiser", price: "0,5l - 2,80€ / 0,33l - 2,40€", description: "", translation: "" },
          { name: "Carlsberg", price: "0,33l - 2,70€ / 0,4l - 2,90€", description: "", translation: "" },
          { name: "Grimbergen", price: "0,33l - 3,20€", description: "", translation: "" },
          { name: "Blanc", price: "0,33l - 2,50€", description: "", translation: "" }
        ]
      },
      {
        subtitle: "SOKOVI / JUICES",
        items: [
          { name: "Jamnica", price: "1l - 3,80€ / 0,1l - 0,40€", description: "", translation: "" },
          { name: "Jana izvorska", price: "0,75l - 3,00€", description: "", translation: "" },
          { name: "Sensation razni okusi / different flavours", price: "0,25l - 2,30€", description: "", translation: "" },
          { name: "Jana vitamin razni okusi / different flavours", price: "0,33l - 2,50€", description: "", translation: "" },
          { name: "Pago razni okusi / different flavours", price: "0,2l - 2,50€", description: "", translation: "" },
          { name: "Cedevita razni okusi / different flavours", price: "0,25l - 2,10€", description: "", translation: "" },
          { name: "Hidra", price: "0,5l - 2,20€", description: "", translation: "" }
        ]
      },
      {
        subtitle: "GAZIRANI SOKOVI / SODAS",
        items: [
          { name: "Coca cola", price: "0,25l - 2,50€", description: "", translation: "" },
          { name: "Coca cola zero", price: "0,25l - 2,50€", description: "", translation: "" },
          { name: "Fanta", price: "0,25l - 2,50€", description: "", translation: "" },
          { name: "Sprite", price: "0,25l - 2,50€", description: "", translation: "" },
          { name: "Schweppes Tangerina / Bitter lemon / Tonic", price: "0,25l - 2,50€", description: "", translation: "" },
          { name: "Red bull", price: "0,25l - 2,50€", description: "", translation: "" }
        ]
      },
      {
        subtitle: "POSEBNA PONUDA / SPECIAL OFFER",
        items: [
          { name: "Aperol spritz", price: "0,3l - 6,00€", description: "", translation: "" }
        ]
      },
      {
        subtitle: "TOPLI NAPITCI / HOT BEVERAGES",
        items: [
          { name: "Espresso", price: "1,50€", description: "", translation: "" },
          { name: "Macchiato", price: "1,80€", description: "", translation: "" },
          { name: "Kava sa šlagom / coffee whipped cream", price: "2,00€", description: "", translation: "" },
          { name: "Kava s mlijekom / milk coffee", price: "1,80€", description: "", translation: "" },
          { name: "Cappuccino classic (čokolada / kokos / vanilija) (chocolate / coconut / vanilla)", price: "2,00€", description: "", translation: "" },
          { name: "Topla čokolada / hot chocolate", price: "2,00€", description: "", translation: "" },
          { name: "Čaj s medom i limunom / Tea with honey and lemon", price: "2,20€", description: "", translation: "" }
        ]
      }
    ]
  }
];

const ITEMS_PER_PAGE = 1;

const Menu = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const menuTopRef = useRef(null);

  const totalPages = Math.ceil(menuData.length / ITEMS_PER_PAGE);

  const scrollToHeader = () => {
    setTimeout(() => {
      menuTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      window.scrollBy(0, -15000); // Adjust the value to create space above the header
    }, 0);
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => {
      const newPage = Math.max(prevPage - 1, 0);
      scrollToHeader();
      return newPage;
    });
  };

  const handleNext = () => {
    setCurrentPage((prevPage) => {
      const newPage = Math.min(prevPage + 1, totalPages - 1);
      scrollToHeader();
      return newPage;
    });
  };

  const handleReset = () => {
    setCurrentPage(0);
    scrollToHeader();
  };

  const getCurrentPageData = () => {
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return menuData.slice(startIndex, endIndex);
  };

  const formatTitle = (title) => {
    const parts = title.split(' / ');
    if (parts.length === 2) {
      return (
        <>
          {parts[0]} <span className="menu-item-slash">/</span><br className="title-break" />
          {parts[1]}
        </>
      );
    }
    return <>{title}</>;
  };

  const formatPrice = (price) => {
    const parts = price.split(' / ');
    return parts.map((part, index) => (
      <div key={index} className="menu-item-price-part">{part}</div>
    ));
  };

  return (
    <div id="menu" className="container menu-container">
      <div ref={menuTopRef} style={{ paddingTop: '100px', marginTop: '-100px' }}></div>
      <div className="menu-header">
        <h1 className="menu-title">Menu</h1>
        <img src={logo} alt="Vedi Logo" className="menu-logo" />
      </div>
      {getCurrentPageData().map((section, sectionIndex) => (
        <section key={sectionIndex} className="menu-section">
          <h2>{section.title}</h2>
          {section.items ? (
            <ul>
              {section.items.map((item, itemIndex) => (
                <li key={itemIndex} className="menu-item">
                  <div className="menu-item-line">
                    <span className="menu-item-name">{formatTitle(item.name)}</span>
                    <span className="menu-item-dots"></span>
                    <div className="menu-item-price">{formatPrice(item.price)}</div>
                  </div>
                  {item.description && (
                    <small><i>{item.description}</i></small>
                  )}
                  {item.translation && (
                    <small><i>{item.translation}</i></small>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            section.subcategories.map((subcategory, subcategoryIndex) => (
              <div key={subcategoryIndex}>
                <h3>{subcategory.subtitle}</h3>
                <ul>
                  {subcategory.items.map((item, itemIndex) => (
                    <li key={itemIndex} className="menu-item">
                      <div className="menu-item-line">
                        <span className="menu-item-name">{formatTitle(item.name)}</span>
                        <span className="menu-item-dots"></span>
                        <div className="menu-item-price">{formatPrice(item.price)}</div>
                      </div>
                      {item.description && (
                        <small><i>{item.description}</i></small>
                      )}
                      {item.translation && (
                        <small><i>{item.translation}</i></small>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))
          )}
        </section>
      ))}
      <div className="pagination">
        <button onClick={handlePrevious} disabled={currentPage === 0}>
          Prethodna
        </button>
        <span>
          Stranica {currentPage + 1} od {totalPages}
        </span>
        <button onClick={handleNext} disabled={currentPage === totalPages - 1}>
          Slijedeća
        </button>
      </div>
      {currentPage !== 0 && (
        <div className="reset-button">
          <button onClick={handleReset}>
            Početna
          </button>
        </div>
      )}
    </div>
  );
};

export default Menu;