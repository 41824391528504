import React, { useState } from 'react';
import './ContactUs.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = formData;

    const mailtoLink = `mailto:vedi.bistro@gmail.com?subject=Contact%20from%20${encodeURIComponent(name)}&body=${encodeURIComponent(`Name: ${name}\nEmail: ${email}\nMessage: ${message}`)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div id="contact" className="container mt-5 pt-5">
      <h1 className="fade-in fade-in-header">Kontaktiraj nas</h1>
      <div className="contact-details fade-in fade-in-contact-details">
        <p>Telefon: <a href="tel:+38543525190" className="phone-link-2">+385 43 525 190</a></p>
        <p>Radno vrijeme: <br/>Ponedjeljak - Subota 10:00 - 23:00</p>
        <p>Radno vrijeme kuhinje: 10:00 - 22:00</p>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12 fade-in fade-in-form">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Ime i Prezime:</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Email adresa:</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Poruka:</label>
              <textarea
                className="form-control"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Pošalji</button>
          </form>
        </div>
        <div className="col-md-6 col-sm-12 fade-in fade-in-map">
          <div className="map-responsive">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2781.235678290022!2d16.838238715879664!3d45.542350379102825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4766d06531c2ec9f%3A0xc4f1f915d8b8ed7b!2sUl.%20Antuna%20Mihanovi%C4%87a%2021A%2C%2043000%2C%20Bjelovar%2C%20Croatia!5e0!3m2!1sen!2s!4v1620924982944!5m2!1sen!2s"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Google Map"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
