import React from 'react';
// import logo from '../../assets/vedi2.png'; // Ensure you have the logo file in this path
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white">
      <div className="container">
        {/* <div className="row justify-content-center">
          <div className="col-auto text-center logo">
            <img src={logo} alt="Logo" width="229" height="178" />
          </div>
        </div> */}
        <div className="row justify-content-center mt-3">
          <div className="col-auto text-center">
            <span>Zapratite nas: </span>
            <a href="https://www.instagram.com/vedi_bistro/" className="social-link" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram fa-2x"></i>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61559301722250" className="social-link" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f fa-2x"></i>
            </a>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-auto text-center contact-info-inline">
            <p><i className="fas fa-map-marker-alt"></i> <span>Adresa: Ul. Antuna Mihanovića 21A, 43000 Bjelovar</span></p>
            <p><i className="fas fa-phone"></i> <span>Telefon: <a href="tel:+38543525190" className='phone-link-2'>+385 43 525 190</a></span></p>
            <p><i className="fas fa-envelope"></i> <span>Email: vedi.bistro@gmail.com</span></p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col text-center">
            <p className="non-clickable">&copy; 2024 Bistro Vedi</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
