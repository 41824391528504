import React, { useEffect } from 'react';
import './AboutUs.css';
import imgOsoblje from '../../assets/staff.jpeg';
import imgLegenda from '../../assets/vedis.jpeg'; // Add the new image

const AboutUs = () => {
  useEffect(() => {
    const elements = document.querySelectorAll('.fade-in-element');
    elements.forEach((element, index) => {
      setTimeout(() => {
        element.classList.add('fade-in');
      }, index * 300); // Delay each element's fade-in by 300ms
    });
  }, []);

  return (
    <div id="about" className="container mt-5 pt-5">
      <h1 className="header-title fade-in-element">O nama</h1>
      <div className="about-content fade-in-element">
        <div className="text-section">
          <p className="about-text fade-in-element">
            <span className="first-letter">B</span>istro Vedi donosi Vam jedinstveno gastronomsko iskustvo koje spaja bogatu tradiciju našeg kraja s modernim kulinarskim tehnikama. Inspirirani mitologijom Bilogore, gdje su drevne šume i mistični izvori stvarali legende o mitskim bićima, u našem bistrou legendu pretvaramo u stvarnost za Vaše nepce. Koristimo isključivo najkvalitetnije, svježe, lokalne namirnice koje pažljivo biramo od provjerenih dobavljača. Svako jelo pripremamo s ljubavlju i pažnjom, osiguravajući da svaki zalogaj nosi autentične okuse Bilogore. Naš meni prožet je tradicionalnim receptima koje smo obogatili modernim kulinarskim dodirom. Od sočnih pečenja, aromatičnih juha do svježih salata i neodoljivih deserata, svaki obrok je prava gastronomska priča. Osim konstantnog menija u ponudi imamo i dnevna jela, odnosno „Gablece“ koje također pripremamo od kvalitetnih, svježih, sezonskih namirnica s lokalne tržnice.

            Pozivamo vas da osjetite čaroliju Bilogore u svakom našem tanjuru. Bistro Vedi nije samo mjesto za objedovanje, već doživljaj koji vas povezuje s prirodom i tradicijom našeg zavičaja. U našem bistrou također je moguće organizirati i manje svečanosti, vjenčanja, rođendane, domjenke, poslovne ručkove i ostala druženja.

            Radujemo se Vašem dolasku!

            VEDI ~ OKUSI MOĆ LEGENDE~
          </p>
        </div>
        <div className="image-section fade-in-element">
          <img src={imgOsoblje} alt="Vedi Bistro osoblje" className="about-image" />
        </div>
      </div>
      <h2 className="header-title fade-in-element">Legenda o Vedima</h2>
      <div className="legenda-content fade-in-element">
        <div className="legenda-image-section fade-in-element">
          <img src={imgLegenda} alt="Legenda o Vedima" className="legenda-image" />
        </div>
        <div className="legenda-text-section fade-in-element">
          <p className="legenda-text fade-in-element">
            <span className="first-letter">J</span><span className='text-end'>ednom davno u velikim šumama, od vrhova Bilogore pa sve do Kalinovca i Ferdinandovca živjela su neobična bića, nalik ljudima. Ondašnji stanovnici nazvali su ih Vedi, bili su muškog roda, visoki kao kuća, imali su velika prsa, a tijelo im je bilo prekriveno gustom dlakom. Govorilo se da su bili veoma snažni, toliko da bi svojim disanjem mogli izazvati oluju, a rukama su mogli čupati stabla i nositi velike terete. Kad su govorili ili pjevali, čulo ih se nadaleko. Bili su obučeni, ali vrlo bijedno i oskudno stoga je krajem često znala kružiti poslovica „Zdrapan si kak Ved!“. Bilo ih je dobrih i zlih. Dobri Vedi bili su vrlo privrženi ljudima i često su im pomagali, ako bi se očekivala kakva oluja ili druga nedaća ljudi su se često molili i zvali Vede u pomoć, a oni bi vrlo brzo došli i pomogli, pa je kružila i poslovica “ Dobejžal’ je brzo kak Ved”. Gotovo svaka kuća imala je svog Veda, jedan Ved je za svog gospodara bio jako dobar dok je za druge ljude i njihovog Veda mogao biti zao i loš. Zli Vedi nisu se družili s ljudima već su ostajali u šumama pa su ih zvali „Šumski Vedi“. Smatra se da su Vedi živjeli u plemenima, duboko u šumama gdje su imali i svoje gradove. Iako su bili veliki i snažni nisu bili besmrtni, na više mjesta u zemlji pronađeni su kosturi ljudi s cjevanicom dužom i od 80 cm. Krajem 19. stoljeća Vedi postepeno napuštaju ljude, livade, oranice i povlače se duboko u šumu.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
