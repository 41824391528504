import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Gallery.css';

import govedinaPrsut from '../../assets/wapp1.jpeg';
import linguiniPiletina from '../../assets/wapp2.jpeg';
import pileciZabatak from '../../assets/wapp3.jpeg';
import predjeloIzMora from '../../assets/wapp4.jpeg';
import wapp5 from '../../assets/wapp5.jpeg';
import wapp6 from '../../assets/wapp6.jpeg';
import interior01 from '../../assets/interior01.jpeg';
import interior02 from '../../assets/interior02.jpeg';
import interior03 from '../../assets/interior03.jpeg';
import interior04 from '../../assets/interior04.jpeg';
import interior05 from '../../assets/interior05.jpeg';
import interior06 from '../../assets/interior06.jpeg';
import interior07 from '../../assets/interior07.jpeg';
import interior08 from '../../assets/interior08.jpeg';
import interior09 from '../../assets/interior09.jpeg';
import interior10 from '../../assets/interior10.jpeg';
import interior11 from '../../assets/interior11.jpeg';
import interior12 from '../../assets/interior12.jpeg';
import staff02 from '../../assets/staff02.jpeg';
import staff03 from '../../assets/staff03.jpeg';

const images = [
  { src: govedinaPrsut, alt: 'Govedina pršut gorušica' },
  { src: linguiniPiletina, alt: 'Linguini sa piletinom i umakom od rajčice' },
  { src: pileciZabatak, alt: 'Pileći zabatak restani krumpir miješana salata' },
  { src: predjeloIzMora, alt: 'Predjelo iz mora za dvoje' },
  { src: wapp5, alt: 'Predjelo iz mora za dvoje' },
  { src: wapp6, alt: 'Predjelo iz mora za dvoje' },
  { src: interior01, alt: 'Predjelo iz mora za dvoje' },
  { src: interior02, alt: 'Predjelo iz mora za dvoje' },
  { src: interior03, alt: 'Predjelo iz mora za dvoje' },
  { src: interior04, alt: 'Predjelo iz mora za dvoje' },
  { src: interior05, alt: 'Predjelo iz mora za dvoje' },
  { src: interior06, alt: 'Predjelo iz mora za dvoje' },
  { src: interior07, alt: 'Predjelo iz mora za dvoje' },
  { src: interior08, alt: 'Predjelo iz mora za dvoje' },
  { src: interior09, alt: 'Predjelo iz mora za dvoje' },
  { src: interior10, alt: 'Predjelo iz mora za dvoje' },
  { src: interior11, alt: 'Predjelo iz mora za dvoje' },
  { src: interior12, alt: 'Predjelo iz mora za dvoje' },
  { src: staff02, alt: 'Predjelo iz mora za dvoje' },
  { src: staff03, alt: 'Predjelo iz mora za dvoje' }
];

const Gallery = () => {
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const imageIndex = query.get('imageIndex');
    if (imageIndex !== null) {
      setSelectedIndex(parseInt(imageIndex, 10));
    }
  }, [location]);

  const handleSelect = (selectedIndex, e) => {
    setSelectedIndex(selectedIndex);
  };

  const handleImageClick = (index) => {
    if (window.innerWidth > 768) {
      setSelectedIndex(index);
      setShowModal(true);
    }
  };

  const handleClose = () => setShowModal(false);

  const handleNext = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setSelectedIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div id="gallery" className="container mt-5 pt-5">
      <h1 className="text-center mb-4 pt-5">
        <span className="gallery-title-g">Galerija</span>
      </h1>
      <div className="carousel-container">
        <Carousel activeIndex={selectedIndex} onSelect={handleSelect} indicators={false}>
          {images.map((image, index) => (
            <Carousel.Item key={index} onClick={() => handleImageClick(index)}>
              <img
                className="d-block w-100 carousel-image"
                src={image.src}
                alt={image.alt}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <Modal show={showModal} onHide={handleClose} centered size="xl" dialogClassName="modal-90w">
        <Modal.Body className="p-0 modal-body-custom">
          <Button variant="secondary" onClick={handlePrev} className="modal-nav modal-prev">
            &lt;
          </Button>
          <img src={images[selectedIndex].src} alt={images[selectedIndex].alt} className="img-fluid modal-image" />
          <Button variant="secondary" onClick={handleNext} className="modal-nav modal-next">
            &gt;
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Gallery;
