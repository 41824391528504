// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  background-color: #8B4513; /* Warm brown color */
}

.nav-link {
  color: #fff;
  font-family: 'Georgia', serif;
  font-size: 18px;
  cursor: pointer; /* Add cursor style */
}

.nav-link:hover {
  color: #FFD700; /* Gold color */
}

.navbar-brand {
  font-family: 'Georgia', serif;
  font-size: 24px;
  cursor: pointer; /* Add cursor style */
}

@media (max-width: 768px) {
  .nav-link {
    font-size: 16px;
  }

  .navbar-brand img {
    width: 150px;
    height: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/navbar/Navbar.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,qBAAqB;AAClD;;AAEA;EACE,WAAW;EACX,6BAA6B;EAC7B,eAAe;EACf,eAAe,EAAE,qBAAqB;AACxC;;AAEA;EACE,cAAc,EAAE,eAAe;AACjC;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,eAAe,EAAE,qBAAqB;AACxC;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;AACF","sourcesContent":[".navbar {\n  background-color: #8B4513; /* Warm brown color */\n}\n\n.nav-link {\n  color: #fff;\n  font-family: 'Georgia', serif;\n  font-size: 18px;\n  cursor: pointer; /* Add cursor style */\n}\n\n.nav-link:hover {\n  color: #FFD700; /* Gold color */\n}\n\n.navbar-brand {\n  font-family: 'Georgia', serif;\n  font-size: 24px;\n  cursor: pointer; /* Add cursor style */\n}\n\n@media (max-width: 768px) {\n  .nav-link {\n    font-size: 16px;\n  }\n\n  .navbar-brand img {\n    width: 150px;\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
