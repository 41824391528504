import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Home from './components/home/Home';
import AboutUs from './components/about-us/AboutUs';
import ContactUs from './components/contact-us/ContactUs';
import Gallery from './components/gallery/Gallery';
import Menu from './components/menu/Menu';
import Footer from './components/footer/Footer';
import ScrollToTop from './ScrollToTop'; // Import ScrollToTop component
import './App.css';

import bgImage1 from './assets/wapp1.jpeg';
import bgImage2 from './assets/wapp2.jpeg';
import bgImage3 from './assets/wapp3.jpeg';
import bgImage4 from './assets/wapp4.jpeg';
import bgImage5 from './assets/wapp5.jpeg';
import bgImage6 from './assets/wapp6.jpeg';

const backgroundImages = [bgImage1, bgImage2, bgImage3, bgImage4, bgImage5, bgImage6];

const BackgroundImages = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 10000); // Change image every 10 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="background-container">
      {backgroundImages.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Background ${index}`}
          className={index === activeIndex ? 'active' : ''}
        />
      ))}
      <div className="background-overlay"></div>
    </div>
  );
};

const App = () => {
  const location = useLocation();
  const isMenuPage = location.pathname === '/menu';

  return (
    <div className="App">
      <ScrollToTop /> {/* Add ScrollToTop component */}
      <Navbar />
      {isMenuPage && <BackgroundImages />}
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
